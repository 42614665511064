<template>
  <div class="contact-us-right-side-card">
    <div class="contact-us-right-side-card__header">
      <div class="contact-us-right-side-card__image">
        <q-img src="https://flashy-assets20211018140904806600000006.s3.eu-central-1.amazonaws.com/email/showcase-image.jpg" fit="cover"></q-img>
      </div>

      <div class="contact-us-right-side-card__content">
        <h2 class="contact-us-right-side-card__title">Ready to scale your content?</h2>
        <p class="contact-us-right-side-card__description">Let's explore how we can help you create high-quality, stunning content - at any scale.</p>
        <q-btn class="contact-us-right-side-card__cta full-width" color="primary" text-color="secondary" filled unelevated no-caps @click="openEmailClient">
          <template v-slot:default>
            <span class="contact-us-right-side-card__cta-icon">
              <q-icon name="o_chat" />
            </span>
            <span class="contact-us-right-side-card__cta-label">Let's Talk</span>
          </template>
        </q-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactUsRightSideCard',
  methods: {
    openEmailClient() {
      window.location.href = 'mailto:sales@getflashy.com';
    }
  }
};
</script>

<style lang="scss">
.contact-us-right-side-card {
  width: 100%;
  height: 100%;
  background-color: var(--secondary-bg-color);
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  display: flex;

  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 100%;
  }

  &__image {
    height: 100%;
    width: 100%;

    .q-img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    text-align: left;
    padding: 2.5rem;
  }

  &__title {
    @include mona-sans-font(32px, true);
    margin: 0;
    line-height: 1.2;
    color: #000000;
    text-align: left;
  }

  &__description {
    @include inter-font(16px);
    margin: 0;
    line-height: 1.6;
    color: var(--secondary-text-color);
    text-align: left;
  }

  &__cta {
    padding: 0.75rem 2rem;
    border-radius: 8px;
    transition: transform 0.2s ease;

    &:hover {
      transform: translateY(-2px);
    }

    &-icon {
      margin-right: 16px;
      font-weight: lighter;
      font-size: 14px;
    }

    &-label {
      @include inter-font($size: 15px, $bolded: true, $color: var(--primary-text-color));
    }
  }
}
</style>
