import { render, staticRenderFns } from "./ShootsTable.vue?vue&type=template&id=0564e22a&scoped=true&"
import script from "./ShootsTable.vue?vue&type=script&lang=js&"
export * from "./ShootsTable.vue?vue&type=script&lang=js&"
import style0 from "./ShootsTable.vue?vue&type=style&index=0&id=0564e22a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0564e22a",
  null
  
)

export default component.exports
import QSpinnerFacebook from 'quasar/src/components/spinner/QSpinnerFacebook.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpinnerFacebook,QIcon,QTable,QTr,QTh,QTd});
