<template>
  <div class="recent-content">
    <div class="recent-content__header">
      <div class="recent-content__title">Recent Content</div>
      <div class="recent-content__controls q-mb-md" v-if="recentImages && recentImages.length > 0">
        <q-btn flat dense color="info" to="/gallery" label="View All" class="recent-content__view-all-btn" />
      </div>
    </div>
    <div v-if="isLoading" class="recent-content__loading flex flex-center">
      <q-spinner-facebook color="primary" size="50px" />
    </div>
    <div v-else-if="!recentImages || recentImages.length === 0" class="recent-content__empty-state">
      <q-icon name="o_image" size="48px" color="grey-5" />
      <p class="recent-content__no-content">No recent content available</p>
      <p class="recent-content__sub-message">Upload new content or check back later</p>
    </div>
    <q-scroll-area v-else horizontal style="height: 310px" class="recent-content__scroll">
      <div class="row no-wrap q-px-md">
        <!-- Image cards -->
        <div v-for="(image, index) in recentImages" :key="index" class="col-auto q-pr-md">
          <div class="recent-content__card">
            <!-- Image -->
            <q-img :src="image.preview_url" class="recent-content__image">
              <!-- Hover overlay -->
              <div class="recent-content__overlay absolute-full">
                <div class="recent-content__info q-pa-md absolute-bottom-left text-left">
                  <div class="recent-content__info-title">{{ image.shoot_name }}</div>
                  <div class="recent-content__info-time">{{ image.relative_upload_time }}</div>
                </div>
                <div class="flex flex-center absolute-full">
                  <q-btn color="primary" class="recent-content__edit-btn" @click="handleEdit(image)">
                    <q-icon name="edit" class="q-mr-xs" size="18px" />
                    <span class="recent-content__btn-text">Edit with AI</span>
                  </q-btn>
                </div>
              </div>
            </q-img>
          </div>
        </div>
      </div>
    </q-scroll-area>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { ShootsApi } from '@api/index';
import { useQuery } from '@tanstack/vue-query';

export default defineComponent({
  name: 'RecentContentGallery',

  setup(props, { root }) {
    const router = root.$router;

    const { data: recentImages, isLoading } = useQuery({
      queryKey: ['recent-images'],
      queryFn: () =>
        ShootsApi.getRecentContent().then(response => {
          return response.data.images;
        })
    });

    const handleEdit = image => {
      router.push({
        name: 'gallery',
        query: { editImage: JSON.stringify(image) }
      });
    };
    return {
      handleEdit,
      recentImages,
      isLoading
    };
  }
});
</script>

<style lang="scss" scoped>
.recent-content {
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  &__controls {
    padding: 0 16px;
  }

  &__select {
    width: 200px;

    :deep(.q_field__control) {
      background: white;
      border: 1px solid #e0e0e0;
      border-radius: 8px;
      height: 40px;

      &:hover {
        border-color: #fed559;
      }
    }

    :deep(.q-field__label) {
      color: var(--main-text-color);
      font-size: 13px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__title {
    @include inter-font(16px, true);
    color: var(--main-text-color);
    margin: 0;
  }

  &__loading {
    padding: 24px;
    height: 310px;
  }

  &__empty-state {
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 24px;
  }

  &__no-content {
    @include mona-sans-font(18px, true);
    color: var(--main-text-color);
    margin: 16px 0 8px;
  }

  &__sub-message {
    @include inter-font(14px, false);
    color: #666;
    margin: 0;
  }

  &__scroll {
    .q-scrollarea__thumb {
      background: #fed559;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  &__card {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
  }

  &__image {
    transition: all 0.3s ease;
    object-fit: cover;
    height: 280px;
    width: 280px;
  }

  &__info {
    z-index: 3;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: 100%;
    padding: 16px;

    &-title {
      @include mona-sans-font(16px, true);
      color: #ffffff;
      margin-bottom: 6px;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    }

    &-time {
      @include mona-sans-font(13px, false);
      color: #ffffff;
      opacity: 0.95;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    }
  }

  &__edit-btn {
    padding: 6px 16px;
    border-radius: 8px;
    background: #fed559 !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    min-height: 36px;
    transition: all 0.2s ease;

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    }

    .q-icon {
      margin-right: 8px;
      color: #333333;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: auto;
  }

  &__title {
    @include mona-sans-font(24px, true);
    color: var(--main-text-color);
    margin: 0;
  }

  &__view-all-btn {
    @include mona-sans-font(13px, true);
    text-transform: uppercase;
    letter-spacing: 0.4px;
    color: #333333;
  }

  &__btn-text {
    @include inter-font(13px, true);
    letter-spacing: 0.4px;
    color: #333333;
    text-transform: none;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__overlay {
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.3s ease;
    z-index: 2;
    backdrop-filter: blur(2px);

    .q-btn {
      transform: scale(0.95);
      transition: transform 0.3s ease;
    }
  }

  &__card:hover {
    .recent-content__overlay {
      opacity: 1;

      .q-btn {
        transform: scale(1);
      }
    }

    .recent-content__info {
      opacity: 1;
    }

    .recent-content__image {
      transform: scale(1.05);
    }
  }
}
</style>
