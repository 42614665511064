<template>
  <div class="orders-wrapper-container">
    <div class="header">
      <h1 class="heading"></h1>
      <q-btn-toggle
        v-model="viewMode"
        no-caps
        spread
        :options="[
          { label: '', value: 'list', icon: 'view_list' },
          { label: '', value: 'grid', icon: 'view_module' }
        ]"
        toggle-color="primary"
        toggle-text-color="white"
        text-color="primary"
      />
    </div>

    <div class="orders-container">
      <div class="filters-container">
        <div class="filters-content">
          <q-select v-model="selectedTab" :options="tabOptions" outlined class="status-tab-select compact-select" @input="handleChangedTab" emit-value map-options behavior="menu" dense />

          <q-input filled v-model="searchQuery" debounce="300" placeholder="Search..." @input="searchQueryChanged" @keypress="validateInput" class="filter-input compact-input" dense />

          <q-date v-model="selectedDateRange" range mask="YYYY-MM-DD" format="YYYY-MM-DD" @input="dateFilterChanged" today-btn dense :class="['date-filter', 'compact-date']" minimal />

          <q-select
            v-model="selectedStatuses"
            :options="statusOptions"
            outlined
            multiple
            use-chips
            @input="statusFilterChanged"
            class="filter-select compact-select"
            :label="'Filter by Status'"
            dense
            options-dense
          />

          <q-select
            v-model="selectedServices"
            :options="serviceOptions"
            outlined
            multiple
            use-chips
            @input="serviceFilterChanged"
            class="filter-select compact-select"
            :label="'Filter by Category'"
            dense
            options-dense
          />

          <q-select
            v-model="selectedCities"
            :options="citiesOptions"
            outlined
            multiple
            use-chips
            @input="cityFilterChanged"
            class="filter-select compact-select"
            :label="'Filter by City'"
            dense
            options-dense
          />
        </div>

        <div class="export-section">
          <q-separator class="export-divider" />
          <q-btn filled flat color="primary" text-color="black-1" icon="download" label="Export Data" no-caps @click="triggerExport" class="export-btn" />
        </div>
      </div>

      <div class="table-container">
        <ShootsTab :activeTab="selectedTab" :loading="isLoading" :refetching="isRefetching" :shoots="shoots" />
        <div v-if="totalCount > 0" class="pagination-container">
          <q-pagination v-model="currentPage" :max="totalPages" :ellipses="false" :max-pages="6" color="primary" text-color="secondary" densed direction-links />
          <span>{{ totalCount }} Shoots</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api';
import { useQuery } from '@tanstack/vue-query';
import ShootsTab from '@/components/shoot/ShootsTab.vue';
import { ShootsApi } from '@api/index';
import helpers, { UPCOMING_SHOOT, SHOOT_IN_PROGRESS, DOWNLOAD_READY, ARCHIVED_SHOOT, ALL_BOOKINGS } from '@utils/shootsHelpers';
import { exportFile } from 'quasar';

const fetchShoots = async (page, filters, totalCount = null, isExport = false) => {
  // Simulate a delay before executing the API call
  const offset = (page - 1) * 50;
  let pagination;
  if (isExport) {
    pagination = {
      offset: 0,
      size: totalCount
    };
  } else {
    pagination = {
      offset,
      size: 50
    };
  }
  let payload = {
    filters,
    pagination
  };
  const response = await ShootsApi.searchShoots(payload);
  return {
    shoots: response.data.shoots,
    totalCount: response.data.total
  };
};

export default {
  name: 'Orders',
  components: {
    ShootsTab
  },
  setup(props, { root }) {
    const store = root.$store;

    root.$router.replace({ query: { view: 'list' } });
    const viewMode = ref('list');
    const selectedTab = ref(ALL_BOOKINGS);
    const page = ref(1);
    const searchQuery = ref(''); // Initialize the search query

    const isPhotographer = computed(() => root.$store.getters['user/isPhotographer']);
    const toggleView = computed(() => root.$store.getters['shoot/getActiveView']);
    const showFilters = computed(() => root.$store.getters['shoot/getShowFilters']);

    const services = computed(() => store.getters['services/getServices']);
    const citiesOptions = ref([]);
    const selectedCities = ref([]);

    const fetchFilters = async () => {
      const response = await ShootsApi.getFilters();
      citiesOptions.value = response.data.cities.map(city => ({
        label: city,
        value: city
      }));
    };

    if (citiesOptions.value.length === 0) {
      fetchFilters();
    }

    const selectedDateRange = ref({
      from: null,
      to: null
    });

    const selectedServices = ref([]);
    const serviceOptions = computed(() =>
      services.value.map(service => ({
        label: service.name,
        value: service.id
      }))
    );

    const tabOptions = computed(() => [
      { label: root.$i18n.tc('STATUSES.ALL_BOOKINGS', 0), value: ALL_BOOKINGS },
      { label: root.$i18n.tc('STATUSES.UPCOMING_SHOOT', 0), value: UPCOMING_SHOOT },
      { label: root.$i18n.tc('STATUSES.SHOOT_IN_PROGRESS', 0), value: SHOOT_IN_PROGRESS },
      { label: root.$i18n.tc('STATUSES.DOWNLOAD_READY', 0), value: DOWNLOAD_READY },
      { label: root.$i18n.tc('STATUSES.ARCHIVED_SHOOT', 0), value: ARCHIVED_SHOOT }
    ]);

    const filters = ref({
      statuses: helpers.retrieveBEStatuses({ isAdmin: true, status: ALL_BOOKINGS })
    });

    const selectedStatuses = ref([]);
    // Compute status options based on the active tab
    const statusOptions = computed(() => {
      switch (selectedTab.value) {
        case ALL_BOOKINGS:
          return [
            { label: 'Pending', value: 'scheduled' },
            { label: 'Assigned', value: 'assigned' },
            { label: 'Confirmed', value: 'confirmed' },
            { label: 'Ready for editing', value: 'uploaded' },
            { label: 'Ready for download', value: 'ready' },
            { label: 'Completed', value: 'completed' },
            { label: 'Canceled', value: 'canceled' }
          ];
        case UPCOMING_SHOOT:
          return [
            { label: 'Pending', value: 'scheduled' },
            { label: 'Assigned', value: 'assigned' },
            { label: 'Confirmed', value: 'confirmed' }
          ];
        case SHOOT_IN_PROGRESS:
          return [{ label: 'Ready for editing', value: 'uploaded' }];
        case DOWNLOAD_READY:
          return [{ label: 'Ready for download', value: 'ready' }];
        case ARCHIVED_SHOOT:
          return [
            { label: 'Completed', value: 'completed' },
            { label: 'Canceled', value: 'canceled' }
          ];
        default:
          return [];
      }
    });

    const getPrice = shoot => {
      if (isPhotographer.value) return shoot.photographer_revenue;
      return shoot.price;
    };

    const getStatus = status => {
      return helpers.mapStatus(status);
    };

    const getCategory = rowServiceId => {
      const service = services.value.find(_service => _service.id === rowServiceId) || {};
      return service.name;
    };

    const formatDate = () => {
      const now = new Date();
      return now.getFullYear() + '-' + (now.getMonth() + 1).toString().padStart(2, '0') + '-' + now.getDate().toString().padStart(2, '0');
    };

    const triggerExport = async () => {
      const { shoots } = await fetchShoots(1, filters.value, totalCount.value, true);

      const columns = [
        { label: 'Shoot ID', field: row => row.id },
        { label: 'Date', field: row => (row.datetime ? row.datetime.split('T')[0] : 'No Date') },
        { label: 'Customer', field: row => row.customer_name || 'No Customer' },
        { label: 'Location', field: row => (row.address && row.address.city ? row.address.city : 'No City') },
        { label: 'Category', field: row => getCategory(row.services[0].service_id) },
        { label: 'Price', field: row => `${getPrice(row)} AED` },
        { label: 'Status', field: row => getStatus(row.status) }
      ];

      const rows = shoots;

      // Prepare the CSV content
      const csvContent = [columns.map(col => `"${col.label}"`).join(',')]
        .concat(
          rows.map(row =>
            columns
              .map(col => {
                const value = col.field(row); // Accessing the function directly now
                return `"${String(value).replace(/"/g, '""')}"`;
              })
              .join(',')
          )
        )
        .join('\r\n');

      const fileName = `${selectedTab.value}_${formatDate()}.csv`; // Append date stamp to filename
      const status = exportFile(fileName, csvContent, 'text/csv');

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        });
      }
    };

    const {
      data: shootData,
      isLoading,
      isRefetching,
      refetch
    } = useQuery({
      queryKey: ['shoots', page, selectedTab, filters],
      queryFn: () => fetchShoots(page.value, filters.value),
      keepPreviousData: true,
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60 * 24 // 24 hours
    });

    const shoots = computed(() => shootData.value?.shoots || []);
    const totalCount = computed(() => shootData.value?.totalCount || 0);
    const totalPages = computed(() => Math.ceil(totalCount.value / 50));

    watch(viewMode, newValue => {
      root.$router.replace({ query: { view: newValue } });
      // Optionally, handle additional logic when view mode changes
    });

    onMounted(() => {
      if (root.$route.query.refetch === 'true') {
        refetch();
      }
    });

    const handleChangedTab = async newValue => {
      selectedTab.value = newValue;

      page.value = 1;
      searchQuery.value = '';

      const newFilters = { ...filters.value };
      newFilters.statuses = helpers.retrieveBEStatuses({ isAdmin: true, status: newValue });
      delete newFilters.services;
      delete newFilters.date;
      delete newFilters.searchQuery;

      searchQuery.value = '';
      filters.value = newFilters;

      selectedServices.value = [];
      selectedDateRange.value = { from: null, to: null };
      selectedStatuses.value = []; // Changed from statusOptions.value to empty array
    };

    // Watcher to update filters based on selected statuses
    const statusFilterChanged = () => {
      if (selectedStatuses.value && selectedStatuses.value.length > 0) {
        filters.value = { ...filters.value, statuses: selectedStatuses.value.map(status => status.value) };
      } else {
        // If no statuses are selected, revert to default statuses for the current tab
        const newFilters = { ...filters.value };
        newFilters.statuses = helpers.retrieveBEStatuses({ isAdmin: true, status: selectedTab.value });
        filters.value = newFilters;
      }
    };

    const serviceFilterChanged = () => {
      if (selectedServices.value && selectedServices.value.length > 0) {
        filters.value = { ...filters.value, services: selectedServices.value.map(service => service.value) || [] };
      } else {
        const newFilters = { ...filters.value };
        delete newFilters.services;
        filters.value = newFilters;
      }
    };

    const searchQueryChanged = () => {
      if (searchQuery.value !== '') {
        filters.value = { ...filters.value, searchQuery: searchQuery.value };
      } else {
        const newFilters = { ...filters.value };
        delete newFilters.searchQuery; // Make sure this matches the property name used when adding to filters
        filters.value = newFilters;
      }
    };

    const validateInput = event => {
      // Regex to allow letters, numbers, and space
      const regex = /^[a-zA-Z0-9 ]+$/;

      // Get the character from the event
      const char = String.fromCharCode(event.charCode || event.keyCode);

      // Check if the character matches the regex
      if (!char.match(regex)) {
        event.preventDefault(); // Prevent the character from being input
      }
    };

    const cityFilterChanged = () => {
      filters.value = { ...filters.value, cities: selectedCities.value.map(city => city.label) };
    };

    const dateFilterChanged = () => {
      if (selectedDateRange.value.from && selectedDateRange.value.to) {
        filters.value = { ...filters.value, date: { from: selectedDateRange.value.from, to: selectedDateRange.value.to } };
      } else {
        const newFilters = { ...filters.value };
        delete newFilters.date;
        filters.value = newFilters;
      }
    };

    return {
      shoots,
      totalPages,
      totalCount,
      currentPage: page,
      isLoading,
      isRefetching,
      selectedTab,
      tabOptions,
      toggleView,
      showFilters,
      handleChangedTab,
      searchQuery,
      selectedStatuses,
      statusOptions,
      statusFilterChanged,
      serviceOptions,
      selectedServices,
      serviceFilterChanged,
      cityFilterChanged,
      citiesOptions,
      selectedCities,
      dateFilterChanged,
      selectedDateRange,
      viewMode,
      searchQueryChanged,
      validateInput,
      triggerExport
    };
  }
};
</script>

<style scoped lang="scss">
.orders-wrapper-container {
  height: 100%;
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg-color);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;

  .heading {
    @include mona-sans-font($size: 28px, $bolded: true, $color: var(--primary-text-color));
    margin: 0;
    letter-spacing: -0.5px;
  }
}

.orders-container {
  display: grid;
  grid-template-columns: minmax(280px, 350px) 1fr;
  gap: 24px;
  flex: 1;
  height: calc(100vh - 120px);
  overflow: hidden;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    height: auto;
    overflow: visible;
  }
}

.filters-container {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
  max-width: 350px;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 200px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;

  .filters-content {
    flex: 1;
    overflow-y: auto;
    padding-right: 8px;
    margin-bottom: 16px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }
  }

  .status-tab-select,
  .filter-input,
  .filter-select,
  .date-filter {
    margin-bottom: 12px;
    width: 100%;
  }

  .compact-select {
    :deep(.q-field__control) {
      height: 36px;
      min-height: 36px;
      padding: 0 8px;
    }

    :deep(.q-field__label) {
      font-size: 12px;
    }

    :deep(.q-field__native) {
      padding: 0;
      line-height: 36px;
      min-height: 36px;
    }

    :deep(.q-field__marginal) {
      height: 36px;
    }

    :deep(.q-chip) {
      height: 22px;
      font-size: 11px;
      padding: 0 6px;
    }

    :deep(.q-chip__content) {
      padding: 0 4px;
    }

    :deep(.q-chip__icon) {
      font-size: 14px;
    }
  }

  .compact-input {
    :deep(.q-field__control) {
      height: 36px;
      min-height: 36px;
    }

    :deep(.q-field__native) {
      padding: 0;
      min-height: 36px;
    }
  }

  .compact-date {
    :deep(.q-date__header) {
      padding: 4px 8px;
      min-height: 40px;
    }

    :deep(.q-date__content) {
      padding: 4px;
    }

    :deep(.q-date__navigation) {
      height: 30px;
    }

    :deep(.q-date__calendar-item) {
      height: 28px;
      width: 28px;
      font-size: 11px;
    }

    :deep(.q-date__today) {
      height: 28px;
      width: 28px;
    }
  }

  .export-section {
    margin-top: auto;
    padding-top: 8px;
  }

  .export-divider {
    margin: 8px 0 12px;
  }

  .export-btn {
    width: 100%;
    @include inter-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
    height: 44px;
    border-radius: 8px;
    background-color: var(--main-btn-color);
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.9;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

.table-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  overflow: hidden;
}

.pagination-container {
  position: sticky;
  bottom: 0;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  background-color: #ffffff;
  z-index: 1;

  & > span {
    @include inter-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
    opacity: 0.8;
  }
}

.q-date {
  min-width: unset !important;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
</style>
