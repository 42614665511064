import { render, staticRenderFns } from "./RecentContentGallery.vue?vue&type=template&id=858cfe76&scoped=true&"
import script from "./RecentContentGallery.vue?vue&type=script&lang=js&"
export * from "./RecentContentGallery.vue?vue&type=script&lang=js&"
import style0 from "./RecentContentGallery.vue?vue&type=style&index=0&id=858cfe76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "858cfe76",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerFacebook from 'quasar/src/components/spinner/QSpinnerFacebook.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QSpinnerFacebook,QIcon,QScrollArea,QImg,QField});
