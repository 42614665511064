<template>
  <div class="dashboard">
    <div class="dashboard__header">
      <div class="dashboard__welcome">
        <p class="dashboard__title">Welcome, {{ name }}</p>
      </div>
      <div class="dashboard__primary-action">
        <BookNewShootButton />
      </div>
    </div>

    <div class="dashboard__grid-container">
      <div class="dashboard__quick-actions">
        <q-btn v-for="(action, index) in quickActions" :key="index" class="dashboard__action-btn" flat no-caps @click.native="action.handler">
          <div class="dashboard__action-btn-content">
            <div class="dashboard__action-btn-icon">
              <q-icon :name="action.icon" size="sm" color="grey-7" />
            </div>
            <span class="dashboard__action-btn-label">{{ action.label }}</span>
          </div>
        </q-btn>
      </div>
      <template v-if="isLoading">
        <div v-for="i in 4" :key="`skeleton-${i}`" class="dashboard__stats-card dashboard__skeleton-card">
          <q-skeleton type="rect" height="24px" width="60%" class="q-mb-md" />
          <q-skeleton type="text" width="40%" class="q-mb-sm" />
          <q-skeleton type="text" width="70%" />
          <div class="dashboard__skeleton-footer">
            <q-skeleton type="text" width="80%" />
          </div>
        </div>
        <div class="dashboard__contact-us dashboard__skeleton-contact">
          <q-skeleton type="rect" height="100%" />
        </div>
      </template>
      <template v-else>
        <StatsCard
          title="Lifetime Bookings"
          :statNumber="dashboardStats.totalShoots || 0"
          :statPercentage="dashboardStats.totalShootsGrowth || 0"
          statPercentageIcon="arrow_upward"
          footerText="Total bookings completed"
          :loading="isLoading"
          class="dashboard__stats-card"
        />
        <StatsCard
          title="This Month"
          :statNumber="dashboardStats.monthlyShoots || 0"
          :statPercentage="dashboardStats.monthlyShootsGrowth || 0"
          statPercentageIcon="arrow_upward"
          footerText="Bookings completed"
          :loading="isLoading"
          class="dashboard__stats-card"
        />
        <StatsCard
          title="Content Pieces"
          :statNumber="dashboardStats.totalContent || 0"
          :statPercentage="dashboardStats.contentGrowth || 0"
          statPercentageIcon="arrow_upward"
          footerText="Number of content pieces"
          :loading="isLoading"
          class="dashboard__stats-card"
        />
        <StatsCard
          title="Upcoming Bookings"
          :statNumber="dashboardStats.upcomingShoots || 0"
          :statPercentage="dashboardStats.upcomingShootsGrowth || 0"
          statPercentageIcon="arrow_upward"
          footerText="Number of scheduled bookings"
          :loading="isLoading"
          class="dashboard__stats-card"
        />
        <div class="dashboard__contact-us">
          <ContactUsRightSideCard />
        </div>
      </template>
    </div>
    <div class="dashboard__recent-content">
      <RecentContentGallery />
    </div>
  </div>
</template>

<script>
import BookNewShootButton from '@common/BookNewShootButton.vue';
import { ref, computed, onMounted, onUnmounted } from '@vue/composition-api';
import { useQuery } from '@tanstack/vue-query';
import StatsCard from '@components/overview/StatsCard.vue';
import ContactUsRightSideCard from '@components/overview/ContactUsRightSideCard.vue';
import { ShootsApi } from '@api/index';
import RecentContentGallery from '@components/overview/RecentContentGallery.vue';

export default {
  name: 'OverviewDashboard',

  components: {
    BookNewShootButton,
    StatsCard,
    ContactUsRightSideCard,
    RecentContentGallery
  },

  setup(props, { root }) {
    const store = root.$store;

    // Computed properties using store
    const user = computed(() => store.getters['user/getUser']);
    const isClient = computed(() => store.getters['user/isClient']);
    const isPhotographer = computed(() => store.getters['user/isPhotographer']);
    const name = computed(() => user.value && user.value.name);

    // Fetch dashboard stats using Vue Query
    const {
      data: rawDashboardStats,
      isLoading,
      isError
    } = useQuery({
      queryKey: ['dashboardStats'],
      queryFn: () =>
        ShootsApi.getDashboardStats().then(response => {
          // Transform API response to match dashboard requirements
          return {
            totalShoots: response.data.completed_shoots || 0,
            totalShootsGrowth: response.data.completed_shoots_growth || 0,
            monthlyShoots: response.data.monthly_shoots || 0,
            monthlyShootsGrowth: response.data.monthly_shoots_growth || 0,
            upcomingShoots: response.data.upcoming_shoots || 0,
            upcomingShootsGrowth: response.data.upcoming_shoots_growth || 0,
            totalContent: response.data.total_images || 0,
            contentGrowth: response.data.monthly_images_growth || 0
          };
        }),
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60 * 24 // 24 hours
    });

    // Create a computed property for dashboard stats to handle undefined cases
    const dashboardStats = computed(
      () =>
        rawDashboardStats.value || {
          totalShoots: 0,
          totalShootsGrowth: 0,
          monthlyShoots: 0,
          monthlyShootsGrowth: 0,
          upcomingShoots: 0,
          upcomingShootsGrowth: 0,
          totalContent: 0,
          contentGrowth: 0
        }
    );

    // Methods
    const handleViewBookings = () => {
      // Handle booking new shoot
      root.$router.push('/orders');
    };

    const handleViewGallery = () => {
      root.$router.push('/gallery');
    };

    const handleInviteTeamMembers = () => {
      // Handle editing latest shoot
      root.$router.push('/sub-accounts');
    };

    const handleConnectMeta = () => {
      root.$router.push('/integrations');
    };

    // Quick actions
    const quickActions = ref([
      {
        label: 'View Bookings',
        icon: 'o_camera',
        handler: handleViewBookings
      },
      {
        label: 'Browse Gallery',
        icon: 'o_image',
        handler: handleViewGallery
      },
      {
        label: 'Manage Team',
        icon: 'o_group_add',
        handler: handleInviteTeamMembers
      },
      {
        label: 'Connect Accounts',
        icon: 'o_share',
        handler: handleConnectMeta
      }
    ]);

    // Trengo chat widget implementation
    let trengoScript = null;

    onMounted(() => {
      // Initialize Trengo widget
      window.Trengo = window.Trengo || {};
      window.Trengo.key = 'Q8d63ChVr4B7dGMIoQsA';

      // Create and inject the script
      trengoScript = document.createElement('script');
      trengoScript.type = 'text/javascript';
      trengoScript.async = true;
      trengoScript.src = 'https://static.widget.trengo.eu/embed.js';
      document.getElementsByTagName('head')[0].appendChild(trengoScript);
    });

    onUnmounted(() => {
      // Remove the Trengo script when component is destroyed
      if (trengoScript && trengoScript.parentNode) {
        trengoScript.parentNode.removeChild(trengoScript);
      }

      // Clean up Trengo object
      if (window.Trengo) {
        // Remove widget elements if any
        const trengoElements = document.querySelectorAll('[id^="trengo"]');
        trengoElements.forEach(el => {
          if (el && el.parentNode) {
            el.parentNode.removeChild(el);
          }
        });
      }
    });

    return {
      user,
      isClient,
      isPhotographer,
      name,
      quickActions,
      dashboardStats,
      isLoading,
      isError
    };
  }
};
</script>

<style lang="scss">
.dashboard {
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;

  &__header {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    margin-bottom: 24px;
  }

  &__title {
    @include mona-sans-font($size: 32px, $bolded: true, $color: var(--primary-text-color));
    margin: 0;
  }

  &__quick-actions {
    grid-area: quick;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }

  &__action-btn {
    background: white !important;
    border: 1px solid #fed559;
    border-radius: 8px;
    padding: 8px;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(254, 213, 89, 0.1) !important;
      transform: translateY(-2px);
    }

    &-content {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 8px 16px;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: linear-gradient(135deg, rgba(254, 203, 47, 0.15) 0%, rgba(254, 213, 89, 0.15) 100%);
      padding: 6px;
      margin-right: 12px;
      line-height: 0;
    }

    &-label {
      @include inter-font(16px);
      color: var(--main-text-color);
    }
  }

  &__grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      'quick quick quick quick'
      'stats1 stats2 contact contact'
      'stats3 stats4 contact contact';
    gap: 1rem;
  }

  &__stats-card {
    border: 1px solid #fed559;
    &:nth-child(1) {
      grid-area: stats1;
    }
    &:nth-child(2) {
      grid-area: stats2;
    }
    &:nth-child(3) {
      grid-area: stats3;
    }
    &:nth-child(4) {
      grid-area: stats4;
    }
  }

  &__skeleton-card {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
  }

  &__skeleton-footer {
    margin-top: auto;
    padding-top: 16px;
  }

  &__skeleton-contact {
    background: white;
    border-radius: 8px;
    overflow: hidden;
  }

  &__contact-us {
    border: 1px solid #fed559;
    grid-area: contact;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  &__recent-content {
    margin-top: 2rem;
  }

  &__recent-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    text-align: left;
    .q-btn {
      @include inter-font(14px, true);
    }
  }
}
</style>
