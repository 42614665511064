<template>
  <component :is="homepage" />
</template>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Components
import PageLayout from '@components/layout/PageLayout.vue';

// Admin Components
import AdminHomepage from '@/components/admin/AdminHomepage.vue';

// Photographer Components
import Welcome from '@views/Welcome.vue';
import OverviewDashboard from '@views/OverviewDashboard.vue';

/**
 * Component used to build the HomePage for both business and photographer.
 *
 * @dispalyName HomePage
 */
export default {
  name: 'HomePage',
  components: {
    PageLayout
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      isPhotographer: 'user/isPhotographer'
    }),
    /**
     * Computed used to retrieve the homepage based on the user role
     */
    homepage() {
      if (this.isClient || this.isPhotographer) return OverviewDashboard;

      if (this.isAdmin) return AdminHomepage;

      return null;
    }
  }
};
</script>
