<template>
  <div class="stats-card">
    <div class="stats-card__header">
      <p class="stats-card__title">{{ title }}</p>
    </div>
    <div class="stats-card__content">
      <div class="stats-card__stat_number">
        <p class="stats-card__stat_number-value">{{ statNumber }}</p>
      </div>
      <!--      <div class="stats-card__stat_percentage-wrapper" :class="percentageColorClass">-->
      <!--        <q-icon :name="isPositive ? 'arrow_drop_up' : 'arrow_drop_down'" />-->
      <!--        <p class="stats-card__stat_percentage-value">{{ statPercentage }}%</p>-->
      <!--      </div>-->
    </div>
    <div class="stats-card__footer">
      <p class="stats-card__footer-text">{{ footerText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatsCard',
  props: {
    title: {
      type: String,
      required: true
    },
    statNumber: {
      type: Number,
      required: true
    },
    statPercentage: {
      type: Number,
      required: true
    },
    statPercentageIcon: {
      type: String,
      required: true
    },
    footerText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isPositive: this.statPercentage > 0,
      isNegative: this.statPercentage < 0,
      isZero: this.statPercentage === 0
    };
  },
  computed: {
    percentageColorClass() {
      return {
        'stats-card__stat_percentage-wrapper--positive': this.isPositive,
        'stats-card__stat_percentage-wrapper--negative': this.isNegative,
        'stats-card__stat_percentage-wrapper--neutral': this.isZero
      };
    }
  }
};
</script>

<style lang="scss">
.stats-card {
  width: 100%;
  height: 100%;
  background-color: var(--secondary-bg-color);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  padding: 24px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.12);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__title {
    @include inter-font(16px);
    color: var(--secondary-text-color);
    margin: 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__stat_number {
    &-value {
      @include mona-sans-font(36px, true);
      color: var(--main-text-color);
      margin: 0;
    }
  }

  &__stat_percentage-wrapper {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 6px;

    &--positive {
      background-color: rgba(52, 199, 89, 0.1);
      color: #34c759;
      .q-icon {
        color: #34c759;
      }
    }

    &--negative {
      background-color: rgba(255, 59, 48, 0.1);
      color: #ff3b30;
      .q-icon {
        color: #ff3b30;
      }
    }

    &--neutral {
      background-color: rgba(142, 142, 147, 0.1);
      color: #8e8e93;
      .q-icon {
        color: #8e8e93;
      }
    }

    .q-icon {
      font-size: 24px;
    }
  }

  &__stat_percentage-value {
    @include mona-sans-font(14px, true);
    margin: 0;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-text {
      @include inter-font(14px);
      color: var(--secondary-text-color);
      margin: 0;
    }
  }
}
</style>
