<template>
  <div class="container">
    <div v-if="isLoading || isRefetching" class="overlay">
      <q-spinner-facebook color="primary" size="100px" />
    </div>
    <div v-if="!isLoading && !isRefetching && (!shoots || shoots.length === 0)" class="no-data-container">
      <q-icon name="o_photo_camera" size="48px" color="grey-5" />
      <p class="no-data-message">{{ $t('NO_SHOOTS') }}</p>
      <p class="no-data-submessage">Try adjusting your filters or search criteria</p>
    </div>
    <q-table
      v-else
      :data="shoots"
      :columns="tableColumns"
      row-key="id"
      :loading="isLoading || isRefetching"
      class="table"
      :rows-per-page-options="[50]"
      hide-bottom
      flat
      sticky-header
      virtual-scroll
      :virtual-scroll-sticky-size-start="48"
    >
      <template v-slot:header="props">
        <q-tr :props="props" class="sticky-header-row">
          <q-th v-for="col in props.cols" :key="col.name" :props="props" class="sticky-header-cell">
            {{ col.label }}
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props" @click="handleShootSelection(props.row)">
          <q-td v-for="field in shootsFields" :key="field" :props="props">
            <template v-if="field === 'date'">
              <div :data-type="getStatus(props.row.status)" class="shootsTab__bg shootsTab__bg--mobile"></div>
              {{ fromDateTimeToDateAndTimeRange({ from: props.row.datetime, duration: props.row.duration }).date || '--' }}
            </template>
            <template v-else-if="field === 'id'">
              {{ props.row.id }}
            </template>
            <template v-else-if="field === 'customer'">
              {{ props.row.customer_name }}
            </template>
            <template v-else-if="field === 'location'">
              {{ props.row.address && props.row.address.city }}
            </template>
            <template v-else-if="field === 'category'">
              {{ getCategory(props.row.services) }}
            </template>
            <template v-else-if="field === 'price'">
              {{ `${getPrice(props.row)} ${$t('AED')}` }}
            </template>
            <template v-else-if="field === 'status'">
              <div class="status-container">
                <span class="status-indicator" :style="{ backgroundColor: getStatusColor(props.row.status) }"></span>
                {{ getStatus(props.row.status) | firstUppercase }}
              </div>
            </template>
            <template v-else>
              {{ props.row[field] }}
            </template>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ShootsTable',
  props: {
    shoots: {
      type: Array,
      default: () => []
    },
    shootsFields: {
      type: Array,
      default: () => []
    },
    activeTab: {
      type: String,
      default: () => 'ALL_BOOKINGS'
    },
    isLoading: Boolean,
    isRefetching: Boolean,
    getCategory: Function,
    getPrice: Function,
    getStatus: Function,
    getStatusColor: Function,
    showNavigator: Function
  },
  computed: {
    tableColumns() {
      return this.shootsFields.map(field => ({
        name: field,
        required: true,
        label: field === 'id' ? 'ID' : field.charAt(0).toUpperCase() + field.slice(1).replace(/_/g, ' '),
        align: 'left',
        field: row => row[field],
        sortable: true
      }));
    },
    ...mapGetters({
      services: 'services/getServices'
    })
  },
  methods: {
    ...mapActions({
      setSelectedShoot: 'shoot/saveOpenedShoot'
    }),
    handleShootSelection(row) {
      if (row.redeemable_total !== 0) {
        this.setSelectedShoot(row.id);
        this.$emit('open-pkg');
      }
    }
  }
};
</script>
<style scoped lang="scss">
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.no-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
}

.no-data-message {
  @include mona-sans-font($size: 18px, $bolded: true, $color: var(--primary-text-color));
  margin: 16px 0 8px;
}

.no-data-submessage {
  @include inter-font($size: 14px, $color: var(--secondary-text-color));
  margin: 0;
  opacity: 0.8;
}

.table {
  height: 100%;
  width: 100%;

  :deep(.q-table__top),
  :deep(.q-table__bottom),
  :deep(thead tr:first-child th) {
    background-color: white;
  }

  :deep(thead tr th) {
    position: sticky;
    z-index: 1;
  }

  :deep(thead tr:first-child th) {
    top: 0;
  }
}

.sticky-header-row {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}

.sticky-header-cell {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
  @include mona-sans-font($size: 14px, $bolded: true, $color: var(--primary-text-color));
}

.status-container {
  display: flex;
  align-items: center;
}

.status-indicator {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-right: 12px;
  display: inline-block;
}
</style>
